import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import { Card, Spinner } from 'reactstrap';
import { Bar } from 'react-chartjs-2';
import { toast } from 'react-toastify';

import { fetchVerifiedAffordabilitiesAsync, generateSampleDataAsync } from './utils';
import ReportEmpty from '../../../common/ReportEmpty';
import { Colors } from '../../../../constants';

const i18nOpts = { scope: 'components.admin.insights.verifiedBuyingPowerCard.index' };

function getChartData(verifiedAffordabilities) {
  return {
    labels: verifiedAffordabilities.map((c) => c.range),
    datasets: [
      {
        backgroundColor: '#00CEA6',
        data: verifiedAffordabilities.map((c) => c.customersPercentage),
        barPercentage: 0.6,
        borderRadius: 8
      }
    ]
  };
}

const options = {
  scales: {
    x: {
      title: {
        display: true,
        text: i18n.t('amount', i18nOpts),
        font: {
          weight: '500',
          size: 12
        },
        color: Colors.GRAY_600,
        padding: {
          top: 10
        }
      },
      ticks: {
        font: {
          size: 12,
        },
        color: Colors.GRAY_600
      },
      grid: {
        display: false,
        borderColor: Colors.GRAY_100
      }
    },
    y: {
      title: {
        display: true,
        text: i18n.t('percentageOfCustomers', i18nOpts),
        font: {
          weight: '500',
          size: 12
        },
        color: Colors.GRAY_600,
        padding: {
          bottom: 20
        }
      },
      ticks: {
        callback(value, index, ticks) {
          return `${value}%`;
        },
        stepSize: 10,
        color: Colors.GRAY_700
      },
      grid: {
        color: Colors.GRAY_100,
        borderColor: 'white'
      }
    },
  },
  plugins: {
    legend: {
      display: false
    },
    tooltip: {
      callbacks: {
        label(context) {
          return `${context.formattedValue}%`;
        }
      }
    }
  }
};

const VerifiedBuyingPowerCard = ({ company, dateRange }) => {
  const [loading, setLoading] = useState(true);
  const [verifiedAffordabilities, setVerifiedAffordabilities] = useState([]);

  const loadData = () => {
    const loadDataAsync = company?.enableSampleData ? generateSampleDataAsync : fetchDataAsync;
    loadDataAsync()
      .then((items) => setVerifiedAffordabilities(items))
      .catch((e) => toast.error(e.message))
      .finally(() => setLoading(false));
  };

  const fetchDataAsync = () => {
    const variables = { companyId: company?.id || null, customDateRange: dateRange };
    return fetchVerifiedAffordabilitiesAsync(variables);
  };

  useEffect(() => {
    loadData();
  }, [company, dateRange]);

  const data = useMemo(() => getChartData(verifiedAffordabilities), [verifiedAffordabilities]);

  return (
    <Card body className="h-100">
      <div className="mb-4">
        <h4>{i18n.t('title', i18nOpts)}</h4>
        <div className="col-gray-600">{i18n.t('description', i18nOpts)}</div>
      </div>

      <div className="h-100 d-flex justify-content-center align-items-center">
        {loading ? (
          <div className="text-center my-5"><Spinner size="lg" /></div>
        ) : (
          <>
            {verifiedAffordabilities.length ? (
              <Bar data={data} options={options} />
            ) : (
              <ReportEmpty loading={loading} items={verifiedAffordabilities} />
            )}
          </>
        )}
      </div>
    </Card>
  );
};

VerifiedBuyingPowerCard.propTypes = {
  company: PropTypes.objectOf(PropTypes.any),
  dateRange: PropTypes.objectOf(PropTypes.any).isRequired
};

VerifiedBuyingPowerCard.defaultProps = {
  company: null
};

export default VerifiedBuyingPowerCard;
