import React, { useEffect, useState } from 'react';
import {
  Col, FormGroup, Input, Label, Row
} from 'reactstrap';
import PropTypes from 'prop-types';
import { City, Country, State } from 'country-state-city';
import Switch from 'rc-switch';
import { connect } from 'react-redux';
import i18n from 'i18n-js';
import v from 'voca';

import InputError, { isInputInvalid } from '../../../../../ui/InputError';
import ColorPicker from '../../../../../common/ColorPicker';
import { DefaultHomePage } from '../../../../../../constants';
import { getDefaultHomePageName } from '../../../../../../utils/enumUtils';
import { parseBoolean } from '../../../../../../utils/parserUtils';
import { getLocalImageUrl } from '../../../../../../utils/imageUtils';
import ImageViewer from '../../../../../common/ImageViewer';
import ImageUploader from '../../../../../common/ImageUploader';
import { isMasterAdmin, isTecSupport } from '../../../../../../utils/authUtils';
import DomainField from './DomainField';

const i18nOpts = { scope: 'components.admin.companies.settings.tabsContent.companyAndBranding.index' };

const CompanyAndBranding = ({
  form, error, onTextChange, currentUser
}) => {
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [logo, setLogo] = useState(undefined);
  const [icon, setIcon] = useState(undefined);

  const domains = form.domains || [];
  const settings = form.settings || {};
  const design = settings.design || {};

  const onChangeColor = (name, color) => {
    const newSettings = {
      ...settings,
      design: {
        ...design,
        [name]: color.hex
      }
    };

    onTextChange({ target: { name: 'settings', value: newSettings } });
  };

  const onChangeSwitch = (name, checked) => {
    onTextChange({ target: { name, value: checked } });
  };

  const onCountryChange = (event) => {
    const { value, selectedOptions } = event.target;
    onTextChange({ target: { name: 'countryCode', value } });
    onTextChange({ target: { name: 'country', value: !v.isBlank(value) ? selectedOptions[0]?.text : '' } });
    onTextChange({ target: { name: 'stateCode', value: '' } });
    onTextChange({ target: { name: 'state', value: '' } });
    onTextChange({ target: { name: 'city', value: '' } });
  };

  const onStateChange = (event) => {
    const { value, selectedOptions } = event.target;
    onTextChange({ target: { name: 'stateCode', value } });
    onTextChange({ target: { name: 'state', value: !v.isBlank(value) ? selectedOptions[0]?.text : '' } });
    onTextChange({ target: { name: 'city', value: '' } });
  };

  const onDomainChange = (event) => {
    const { name, value } = event.target;
    const index = parseInt(name.split('-')[1], 10);
    const newDomains = [...domains];
    newDomains[index] = value;
    onTextChange({ target: { name: 'domains', value: newDomains } });
  };

  const onAddDomain = () => {
    const newDomains = [...domains, ''];
    onTextChange({ target: { name: 'domains', value: newDomains } });
  };

  const onAddImage = ([image], imageType) => {
    const imageSetters = {
      logo: setLogo,
      icon: setIcon
    };

    if (image && imageSetters[imageType]) {
      imageSetters[imageType](image);
    }
  };

  const getImageUrls = () => {
    const logoUrl = logo === null ? null : (getLocalImageUrl(logo) || form.logoUrl);
    const iconUrl = icon === null ? null : (getLocalImageUrl(icon) || form.iconUrl);
    return { logoUrl, iconUrl };
  };

  useEffect(() => {
    setCountries(Country.getAllCountries());
  }, []);

  useEffect(() => {
    setStates(State.getStatesOfCountry(form.countryCode));
  }, [form.countryCode]);

  useEffect(() => {
    setCities(City.getCitiesOfState(form.countryCode, form.stateCode));
  }, [form.stateCode]);

  useEffect(() => {
    onTextChange({ target: { name: 'logo', value: logo } });
  }, [logo]);

  useEffect(() => {
    onTextChange({ target: { name: 'icon', value: icon } });
  }, [icon]);

  const isMasterAdminRole = isMasterAdmin();
  const isTecSupportRole = isTecSupport();
  const canViewMasterSettings = isMasterAdminRole || isTecSupportRole;
  const { logoUrl, iconUrl } = getImageUrls();

  return (
    <>
      <Row>
        <Col md={12} lg={9} xl={8}>
          <FormGroup row>
            <Label sm={5} className="py-0">
              {i18n.t('companyName', i18nOpts)}
              <span className="help-text">{i18n.t('helpTexts.companyName', i18nOpts)}</span>
            </Label>
            <Col sm={7}>
              <Input
                type="text"
                name="name"
                id="name"
                value={form.name || ''}
                onChange={onTextChange}
                invalid={isInputInvalid(error, 'name')}
              />
              <InputError name="name" error={error} />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={5} className="py-0">
              {i18n.t('country', i18nOpts)}
              <span className="help-text">{i18n.t('helpTexts.country', i18nOpts)}</span>
            </Label>
            <Col sm={7}>
              <Input
                type="select"
                name="countryCode"
                id="countryCode"
                value={form.countryCode || ''}
                onChange={onCountryChange}
                invalid={isInputInvalid(error, 'country')}
              >
                <option value="">{i18n.t('select.select')}</option>
                {
                  countries.map((country) => (
                    <option key={`country-${country.isoCode}`} value={country.isoCode}>{country.name}</option>
                  ))
                }
              </Input>
              <InputError name="country" error={error} />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={5} className="py-0">
              {i18n.t('state', i18nOpts)}
              <span className="help-text">{i18n.t('helpTexts.state', i18nOpts)}</span>
            </Label>
            <Col sm={7}>
              <Input
                type="select"
                name="stateCode"
                id="stateCode"
                value={form.stateCode || ''}
                onChange={onStateChange}
                invalid={isInputInvalid(error, 'state')}
              >
                <option value="">{i18n.t('select.select')}</option>
                {
                  states.map((state) => (
                    <option key={`state-${state.isoCode}`} value={state.isoCode}>{state.name}</option>
                  ))
                }
              </Input>
              <InputError name="state" error={error} />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={5} className="py-0">
              {i18n.t('city', i18nOpts)}
              <span className="help-text">{i18n.t('helpTexts.city', i18nOpts)}</span>
            </Label>
            <Col sm={7}>
              <Input
                type="select"
                name="city"
                id="city"
                value={form.city || ''}
                onChange={onTextChange}
                invalid={isInputInvalid(error, 'city')}
              >
                <option value="">{i18n.t('select.select')}</option>
                {
                  cities.map((c) => (
                    <option key={`city-${c.name}`} value={c.name}>{c.name}</option>
                  ))
                }
              </Input>
              <InputError name="city" error={error} />
            </Col>
          </FormGroup>
        </Col>
      </Row>
      <hr className="mt-0" />
      <Row>
        <Col md={12} lg={9} xl={8}>
          <FormGroup row>
            <Label sm={5} className="py-0">
              {i18n.t('companyLogo', i18nOpts)}
              <span
                className="help-text"
                dangerouslySetInnerHTML={{ __html: i18n.t('helpTexts.companyLogo', i18nOpts) }}
              />
            </Label>
            <Col sm={7}>
              <Row>
                {logoUrl && (
                  <Col sm={4}>
                    <ImageViewer url={logoUrl} onDelete={() => setLogo(null)} />
                  </Col>
                )}
                <Col>
                  <ImageUploader onUpload={(images) => onAddImage(images, 'logo')} maxFiles={1} />
                </Col>
              </Row>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={5} className="py-0">
              {i18n.t('favicon', i18nOpts)}
              <span className="help-text">{i18n.t('helpTexts.favicon', i18nOpts)}</span>
            </Label>
            <Col sm={7}>
              <Row>
                {iconUrl && (
                  <Col sm={4}>
                    <ImageViewer url={iconUrl} onDelete={() => setIcon(null)} />
                  </Col>
                )}
                <Col>
                  <ImageUploader onUpload={(images) => onAddImage(images, 'icon')} maxFiles={1} />
                </Col>
              </Row>
            </Col>
          </FormGroup>
        </Col>
      </Row>
      <hr className="mt-0" />
      <Row>
        <Col md={12} lg={9} xl={8}>
          <FormGroup row>
            <Label sm={5} className="py-0">{i18n.t('textColor', i18nOpts)}</Label>
            <Col sm={7}>
              <ColorPicker
                initialColor={design.mainColor}
                onChange={(color) => onChangeColor('mainColor', color)}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={5} className="py-0">{i18n.t('accentColor', i18nOpts)}</Label>
            <Col sm={7}>
              <ColorPicker
                initialColor={design.accentColor}
                onChange={(color) => onChangeColor('accentColor', color)}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={5} className="py-0">{i18n.t('floorPlanHighlightColor', i18nOpts)}</Label>
            <Col sm={7}>
              <ColorPicker
                initialColor={design.floorPlanHighlightColor}
                onChange={(color) => onChangeColor('floorPlanHighlightColor', color)}
              />
            </Col>
          </FormGroup>
        </Col>
      </Row>
      {canViewMasterSettings && (
        <>
          <hr className="mt-0" />
          <Row>
            <Col md={12} lg={9} xl={8}>
              <FormGroup row>
                <Label sm={5}>{i18n.t('defaultHomePage', i18nOpts)}</Label>
                <Col sm={7}>
                  <Input
                    type="select"
                    name="defaultHomePage"
                    id="defaultHomePage"
                    value={form.defaultHomePage || ''}
                    onChange={onTextChange}
                    invalid={isInputInvalid(error, 'defaultHomePage')}
                  >
                    {
                      Object.values(DefaultHomePage).map((page) => (
                        <option key={`default-home-page-option-${page}`} value={page}>
                          {getDefaultHomePageName(page)}
                        </option>
                      ))
                    }
                  </Input>
                  <InputError name="defaultHomePage" error={error} />
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <hr className="mt-0" />
          <Row>
            <Col md={12} lg={9} xl={8}>
              {isMasterAdminRole && (
                <FormGroup row>
                  <Label sm={5} className="py-0">{i18n.t('enableCORSForJavaScriptSDK', i18nOpts)}</Label>
                  <Col sm={7}>
                    <Switch
                      checked={parseBoolean(form.enableCORSForJavaScriptSDK)}
                      onChange={(checked) => onChangeSwitch('enableCORSForJavaScriptSDK', checked)}
                    />
                  </Col>
                </FormGroup>
              )}
              <FormGroup row>
                <Label sm={5} className="py-0">
                  {i18n.t('domains', i18nOpts)}
                  <span className="help-text">{i18n.t('helpTexts.domains', i18nOpts)}</span>
                </Label>
                <Col sm={7}>
                  {domains.map((domain, index) => (
                    <DomainField
                      key={`domain-${index}`}
                      index={index}
                      value={domain}
                      onChange={onDomainChange}
                      error={error}
                      isLast={index === domains.length - 1}
                    />
                  ))}
                  <div
                    role="button"
                    aria-hidden
                    onClick={onAddDomain}
                    className="d-inline-block text-link font-weight-600 col-gray-600"
                  >
                    {i18n.t('buttons.addDomain', i18nOpts)}
                  </div>
                </Col>
              </FormGroup>
            </Col>
          </Row>
        </>
      )}
      <hr className="mt-0" />
      <Row>
        <Col md={12} lg={9} xl={8}>
          <FormGroup row>
            <Label sm={5} className="py-0">
              {i18n.t('excludeIpAddresses', i18nOpts)}
              <span
                className="help-text"
                dangerouslySetInnerHTML={{ __html: i18n.t('helpTexts.excludeIpAddresses', { ...i18nOpts, ipAddress: currentUser.userIp }) }}
              />
            </Label>
            <Col sm={7}>
              <Input
                type="textarea"
                rows={5}
                name="ipToExclude"
                id="ipToExclude"
                value={form.ipToExclude || ''}
                onChange={onTextChange}
                invalid={isInputInvalid(error, 'ipToExclude')}
                placeholder={currentUser.userIp}
              />
              <InputError name="ipToExclude" error={error} />
            </Col>
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

CompanyAndBranding.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  error: PropTypes.objectOf(PropTypes.any),
  onTextChange: PropTypes.func
};

CompanyAndBranding.defaultProps = {
  error: null,
  onTextChange: () => {}
};

export default connect((store) => ({
  currentUser: store.users.currentUser
}))(CompanyAndBranding);
