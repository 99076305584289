import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import { Card } from 'reactstrap';
import { Bar } from 'react-chartjs-2';

import { fetchMasterAdminMetrics } from './utils';
import ListEmpty from '../../../common/ListEmpty';
import ChartUtils from '../../../../utils/chartUtils';

const i18nOpts = { scope: 'components.admin.masterAdminDashboard.visitorLeadsChart.index' };

const VisitorLeadsChart = ({ customDateRange }) => {
  const [loading, setLoading] = useState(true);
  const [leadGenerations, setLeadGenerations] = useState([]);
  const [visitorsCount, setVisitorsCount] = useState(0);
  const [capturedLeadsCount, setCapturedLeadsCount] = useState(0);

  const loadMetrics = () => {
    fetchMasterAdminMetrics(customDateRange)
      .then((items) => setLeadGenerations(items))
      .finally(() => setLoading(false));
  };

  const setCounters = () => {
    const { visitors, capturedLeads } = leadGenerations.reduce((acc, item) => ({
      visitors: acc.visitors + item.visitors.totalVisitorsCount,
      capturedLeads: acc.capturedLeads + item.capturedLeads.totalLeadsCount
    }), { visitors: 0, capturedLeads: 0 });

    setVisitorsCount(visitors);
    setCapturedLeadsCount(capturedLeads);
  };

  useEffect(() => {
    loadMetrics();
  }, [customDateRange]);

  useEffect(() => {
    setCounters();
  }, [leadGenerations]);

  const getData = () => ({
    labels: leadGenerations.map((d) => d.dateDivision.toUpperCase()),
    datasets: [
      {
        label: i18n.t('visitors', i18nOpts).toUpperCase(),
        data: leadGenerations.map((c) => c.visitors.totalVisitorsCount),
        backgroundColor: ChartUtils.CHART_COLORS.green,
        hoverBackgroundColor: ChartUtils.CHART_COLORS.green,
        order: 1
      },
      {
        type: 'line',
        label: i18n.t('capturedLeads', i18nOpts).toUpperCase(),
        backgroundColor: ChartUtils.transparentize(ChartUtils.CHART_COLORS.purple, 0.5),
        borderColor: ChartUtils.CHART_COLORS.purple,
        fill: true,
        data: leadGenerations.map((c) => c.capturedLeads.totalLeadsCount),
        order: 0,
      },
    ]
  });

  const getOptions = () => ({
    responsive: true,
    plugins: {
      legend: {
        position: 'top'
      },
    }
  });

  return (
    <div>
      <Card body>
        <h4 className="mb-4">{i18n.t('title', i18nOpts)}</h4>

        <ListEmpty loading={loading} items={leadGenerations} />

        {leadGenerations.length > 0 && (
          <div>
            {(visitorsCount > 0 || capturedLeadsCount > 0) && (
            <div className="font-20">
              <span className="col-primary-1 mr-3">{i18n.t('visitorsCount', { ...i18nOpts, count: visitorsCount })}</span>
              <span className="col-violet">{i18n.t('capturedLeadsCount', { ...i18nOpts, count: capturedLeadsCount })}</span>
            </div>
            )}
            <Bar data={{ ...getData() }} options={{ ...getOptions() }} />
          </div>
        )}

      </Card>
    </div>
  );
};

VisitorLeadsChart.propTypes = {
  customDateRange: PropTypes.objectOf(PropTypes.any).isRequired
};

VisitorLeadsChart.defaultProps = {};

export default VisitorLeadsChart;
