import React from 'react';
import i18n from 'i18n-js';
import { Button, Card } from 'reactstrap';

import CopyLink from '../../../../../common/CopyLink';

const i18nOpts = { scope: 'components.admin.integrations.embedWidget.buildHomeSetup.javascriptSdk.index' };

const READY_CODE = `document.addEventListener('ownly.ready', () => {
  // You can safely use window.Ownly here
  Ownly.api.build.add(...);
});`;

const ADD_BUILD_CODE = `try {
  const buildId = await Ownly.api.build.add({
    'community-id': "community1"
     ...
  });
} catch (e) {}`;

const REMOVE_BUILD_CODE = 'Ownly.api.build.remove(YOUR_BUILD_ID);';

const GET_BUILD_URL_CODE = 'const buildLink = Ownly.api.build.getUrl(YOUR_BUILD_ID);';

const WIDGET_CODE = `
  // Open widget
  Ownly.widget.open(YOUR_BUILD_ID);

  // Close widget
  Ownly.widget.close();

  // Get Verified
  Ownly.widget.getVerified();

  // Get Rental Verified
  Ownly.widget.getRentalVerified();

  // Open My Homes
  Ownly.widget.openMyHomes();
`;

const JavaScriptSdk = () => (
  <div>
    <h2>{i18n.t('title', i18nOpts)}</h2>
    <p dangerouslySetInnerHTML={{ __html: i18n.t('description', i18nOpts) }} />

    <Card body className="d-inline-block">
      <pre>
        document.addEventListener(&apos;ownly.ready&apos;, () &#61;&gt; &#123;
        <br />
        &nbsp;&nbsp;&#47;&#47;&nbsp;You can safely use window.Ownly here
        <br />
        &nbsp;&nbsp;Ownly.api.build.add(...);
        <br />
        &#125;);
      </pre>
      <CopyLink text={READY_CODE}>
        <Button color="link" size="sm">{i18n.t('buttons.copy')}</Button>
      </CopyLink>
    </Card>

    <br />

    <h4>{i18n.t('api', i18nOpts)}</h4>
    <p dangerouslySetInnerHTML={{ __html: i18n.t('apiDescription', i18nOpts) }} />
    <p>{i18n.t('example', i18nOpts)}</p>

    <Card body className="d-inline-block">
      <pre>
        try &#123;
        <br />
        &nbsp;&nbsp;const buildId = await Ownly.api.build.add(&#123;
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&apos;community-id&apos;: &quot;community1&quot;
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;...
        <br />
        &nbsp;&nbsp;&#125;);
        <br />
        &#125; catch (e) &#123;&#125;
      </pre>
      <CopyLink text={ADD_BUILD_CODE}>
        <Button color="link" size="sm">{i18n.t('buttons.copy')}</Button>
      </CopyLink>
    </Card>
    <p dangerouslySetInnerHTML={{ __html: i18n.t('apiAttributes', i18nOpts) }} />
    <p dangerouslySetInnerHTML={{ __html: i18n.t('apiRemoveBuild', i18nOpts) }} />
    <Card body className="d-inline-block">
      <pre>
        Ownly.api.build.remove(YOUR_BUILD_ID);
      </pre>
      <CopyLink text={REMOVE_BUILD_CODE}>
        <Button color="link" size="sm">{i18n.t('buttons.copy')}</Button>
      </CopyLink>
    </Card>
    <p dangerouslySetInnerHTML={{ __html: i18n.t('apiGetBuildUrl', i18nOpts) }} />
    <Card body className="d-inline-block">
      <pre>
        const buildLink = Ownly.api.build.getUrl(YOUR_BUILD_ID);
      </pre>
      <CopyLink text={GET_BUILD_URL_CODE}>
        <Button color="link" size="sm">{i18n.t('buttons.copy')}</Button>
      </CopyLink>
    </Card>

    <br />

    <h4>{i18n.t('widget', i18nOpts)}</h4>
    <p dangerouslySetInnerHTML={{ __html: i18n.t('widgetDescription', i18nOpts) }} />
    <Card body className="d-inline-block">
      <pre>
        &#47;&#47;&nbsp;Open widget
        <br />
        Ownly.widget.open(YOUR_BUILD_ID);
        <br />
        <br />
        &#47;&#47;&nbsp;Reserve Now
        <br />
        Ownly.widget.reserve(YOUR_BUILD_ID);
        <br />
        <br />
        &#47;&#47;&nbsp;Close widget
        <br />
        Ownly.widget.close();
        <br />
        <br />
        &#47;&#47;&nbsp;Get Verified
        <br />
        Ownly.widget.getVerified();
        <br />
        <br />
        &#47;&#47;&nbsp;Get Rental Verified
        <br />
        Ownly.widget.getRentalVerified();
        <br />
        <br />
        &#47;&#47;&nbsp;Open My Homes
        <br />
        Ownly.widget.openMyHomes();
      </pre>
      <CopyLink text={WIDGET_CODE}>
        <Button color="link" size="sm">{i18n.t('buttons.copy')}</Button>
      </CopyLink>
    </Card>

    <p dangerouslySetInnerHTML={{ __html: i18n.t('getVerifiedBuildHomeAttributes', i18nOpts) }} />
  </div>
);

JavaScriptSdk.propTypes = {};

JavaScriptSdk.defaultProps = {};

export default JavaScriptSdk;
