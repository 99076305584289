import React from 'react';
import {
  Badge
} from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import _ from 'lodash';

import styles from './CommunitiesProjects.module.scss';
import {
  getCustomerInterestId,
} from '../../../../../../../../utils/customerUtils';
import { setCustomerInterestFilters } from '../../../../../../../../store/actions/customerActions';
import { isUnionTypeObjectEqual } from '../../../../../../../../utils/objectUtils';

const CommunitiesProjects = ({ communitiesProjects, interestFilters, ...props }) => {
  const onCommunityProjectToggle = (communityProject) => {
    const selectedCommunitiesProjects = [...(interestFilters.communitiesProjects || [])];
    const newCommunitiesProjects = _.xorWith(selectedCommunitiesProjects, [communityProject],
      isUnionTypeObjectEqual);
    props.setCustomerInterestFilters({ communitiesProjects: newCommunitiesProjects });
  };

  const isSelectedCommunityProject = (communityProject) => {
    const selectedCommunitiesProjects = interestFilters.communitiesProjects || [];
    return selectedCommunitiesProjects.some((cp) => isUnionTypeObjectEqual(cp,
      communityProject));
  };

  if (communitiesProjects.length < 2) return null;

  return (
    <div className="d-flex gap-2 flex-wrap justify-content-end">
      {
        communitiesProjects.map((communityProject) => (
          <Badge
            key={getCustomerInterestId(communityProject)}
            pill
            color="primary"
            className={classNames(styles.badge,
              { [styles.activeBadge]: isSelectedCommunityProject(communityProject) })}
            role="button"
            aria-hidden
            onClick={() => onCommunityProjectToggle(communityProject)}
          >
            {communityProject.name}
          </Badge>
        ))
      }
    </div>
  );
};

CommunitiesProjects.propTypes = {
  communitiesProjects: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any))
};

CommunitiesProjects.defaultProps = {
  communitiesProjects: []
};

export default connect((store) => ({
  customer: store.customers.customer,
  interestFilters: store.customers.interestFilters,
}), {
  setCustomerInterestFilters
})(CommunitiesProjects);
