import React from 'react';
import PropTypes from 'prop-types';
import {
  Col, FormGroup, Label, Row
} from 'reactstrap';
import Switch from 'rc-switch';
import i18n from 'i18n-js';

import { parseBoolean } from '../../../../../../../utils/parserUtils';
import { confirmAlert } from '../../../../../../global/confirmAlert';

const i18nOpts = { scope: 'components.admin.companies.settings.tabsContent.advancedSettings.exportSettings.index' };

const ExportSettings = ({ form, error, onTextChange }) => {
  const exportSettings = form.exportSettings || {};

  const onConfirm = (name) => {
    const newExportSettings = { ...exportSettings };
    newExportSettings[name] = true;
    onTextChange({ target: { name: 'exportSettings', value: newExportSettings } });
  };

  const onToggle = (name, checked) => {
    const newExportSettings = { ...exportSettings };

    if (checked) {
      const options = {
        title: null,
        message: i18n.t('confirmAlert.message', i18nOpts)
      };
      confirmAlert(() => onConfirm(name), options);
    } else {
      newExportSettings[name] = false;
      onTextChange({ target: { name: 'exportSettings', value: newExportSettings } });
    }
  };

  return (
    <>
      <hr className="mt-0" />
      <Row>
        <Col md={12} lg={9} xl={8}>
          <FormGroup row>
            <Label sm={5} className="py-0">{i18n.t('exportCustomers', i18nOpts)}</Label>
            <Col sm={7}>
              <Switch
                checked={parseBoolean(exportSettings.customers)}
                onChange={(checked) => onToggle('customers', checked)}
              />
            </Col>
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

ExportSettings.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  error: PropTypes.objectOf(PropTypes.any),
  onTextChange: PropTypes.func
};

ExportSettings.defaultProps = {
  error: null,
  onTextChange: () => {}
};

export default ExportSettings;
