import React, { useEffect, useMemo, useState } from 'react';
import i18n from 'i18n-js';
import { Card, Spinner } from 'reactstrap';
import { Doughnut } from 'react-chartjs-2';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import { fetchVerifiedAgesAsync, generateSampleDataAsync } from './utils';
import ReportEmpty from '../../../common/ReportEmpty';

const i18nOpts = { scope: 'components.admin.insights.verifiedAgeDemographicCard.index' };

function getChartData(verifiedAges) {
  return {
    labels: verifiedAges.map((c) => c.range),
    datasets: [
      {
        backgroundColor: ['#00A572', '#00B37F', '#00C191', '#00CEA6', '#ACEAD8'],
        data: verifiedAges.map((c) => c.customersCount)
      }
    ]
  };
}

const options = {
  plugins: {
    legend: {
      position: 'right',
      align: 'start',
      labels: {
        font: {
          weight: '400',
          size: 14
        },
        usePointStyle: true,
        pointStyleWidth: 10,
        boxHeight: 7
      }
    },
    layout: {
      padding: 20
    }
  }
};

const VerifiedAgeDemographicCard = ({ company, dateRange }) => {
  const [loading, setLoading] = useState(true);
  const [verifiedAges, setVerifiedAges] = useState([]);

  const loadData = () => {
    const loadDataAsync = company?.enableSampleData ? generateSampleDataAsync : fetchDataAsync;
    loadDataAsync()
      .then((items) => setVerifiedAges(items))
      .catch((e) => toast.error(e.message))
      .finally(() => setLoading(false));
  };

  const fetchDataAsync = () => {
    const variables = { companyId: company?.id || null, customDateRange: dateRange };
    return fetchVerifiedAgesAsync(variables);
  };

  useEffect(() => {
    loadData();
  }, [company, dateRange]);

  const data = useMemo(() => getChartData(verifiedAges), [verifiedAges]);

  return (
    <Card body className="h-100">
      <h4 className="mb-4">{i18n.t('title', i18nOpts)}</h4>

      <div className="h-100 d-flex justify-content-center align-items-center">
        {loading ? (
          <div className="text-center my-5"><Spinner size="lg" /></div>
        ) : (
          <>
            {verifiedAges.length ? (
              <Doughnut data={data} options={options} />
            ) : (
              <ReportEmpty loading={loading} items={verifiedAges} />
            )}
          </>
        )}
      </div>
    </Card>
  );
};

VerifiedAgeDemographicCard.propTypes = {
  company: PropTypes.objectOf(PropTypes.any),
  dateRange: PropTypes.objectOf(PropTypes.any).isRequired
};

VerifiedAgeDemographicCard.defaultProps = {
  company: null
};

export default VerifiedAgeDemographicCard;
