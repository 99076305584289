export const Tabs = Object.freeze({
  INSTALLATION: 'installation',
  BUILD_HOME: 'buildHome',
  RESERVE_HOME: 'reserveHome',
  MY_HOMES: 'myHomes',
  QUICK_POSSESSIONS: 'quickPossessions',
  JAVASCRIPT_SDK: 'javaScriptSdk',
  GET_VERIFIED: 'getVerified',
  GET_RENTAL_VERIFIED: 'getRentalVerified',
});
