import React, { useMemo } from 'react';
import {
  Col, FormGroup, Label, Row
} from 'reactstrap';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';

import useFetchSalesReps from '../../../../../../../hooks/useFetchSalesReps';
import { MultiSelect } from '../../../../../../ui';

const i18nOpts = { scope: 'components.admin.companies.settings.tabsContent.advancedSettings.leadNotifications.index' };

const LeadNotifications = ({ form, error, onTextChange }) => {
  const [salesReps] = useFetchSalesReps(form.id);
  const leadNotificationSalesReps = form.leadNotificationSalesRepresentatives || [];

  const onSelect = (selectedItems) => {
    onTextChange({ target: { name: 'leadNotificationSalesRepresentatives', value: selectedItems } });
  };

  const availableSalesReps = useMemo(() => salesReps.map((salesRep) => ({
    ...salesRep,
    displayValue: `${salesRep.name} - ${salesRep.email}`
  })),
  [salesReps]);

  return (
    <>
      <hr className="mt-0" />
      <Row>
        <Col md={12} lg={9} xl={8}>
          <FormGroup row>
            <Label sm={5} className="py-0">
              {i18n.t('leadNotification', i18nOpts)}
              <span className="help-text">{i18n.t('helpTexts.leadNotification', i18nOpts)}</span>
            </Label>
            <Col sm={7}>
              <MultiSelect
                id="sales-reps-multiselect"
                options={availableSalesReps}
                selectedValues={leadNotificationSalesReps}
                onSelect={onSelect}
                onRemove={onSelect}
                displayValue="displayValue"
                closeIcon="cancel"
                emptyRecordMsg={i18n.t('emptySalesReps', i18nOpts)}
                placeholder=""
              />
            </Col>
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

LeadNotifications.propTypes = {
  form: PropTypes.objectOf(PropTypes.any).isRequired,
  error: PropTypes.objectOf(PropTypes.any),
  onTextChange: PropTypes.func
};

LeadNotifications.defaultProps = {
  error: null,
  onTextChange: () => {}
};

export default LeadNotifications;
