import React from 'react';

import ActiveDeals from './Deals';
import Interests from './Interests';

const Deals = () => (
  <div>
    <ActiveDeals />
    <Interests />
  </div>
);

Deals.propTypes = {};

Deals.defaultProps = {};

export default Deals;
