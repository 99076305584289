import React, { useEffect, useState } from 'react';
import {
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import { connect } from 'react-redux';
import v from 'voca';
import { toast } from 'react-toastify';
import i18n from 'i18n-js';

import ModalService from '../../../../../../../services/ModalService';
import { ModalNames } from '../../../../../../../constants';
import { hasFormValues } from '../../../../../../../utils/formUtils';
import InputError, { isInputInvalid } from '../../../../../../ui/InputError';
import PhoneNumberInput from '../../../../../../global/PhoneNumberInput';
import { sendGridSaveContact } from '../../../../../../../store/actions/sendGridActions';
import { getError } from '../../../../../../../utils/requestUtils';
import { stripToEmpty } from '../../../../../../../utils/stringUtils';

const i18nOpts = { scope: 'components.admin.integrations.sendGrid.contactLists.contacts.formModal.index' };

function onClose() {
  ModalService.close(ModalNames.SENDGRID_CONTACT_FORM);
}

const FormModal = ({
  opened, params, saving, contactList, currentCompany, ...props
}) => {
  const [form, setForm] = useState({});
  const [error, setError] = useState(null);

  const onTextChange = (event) => {
    const { name, value } = event.target;
    setFormValues(name, value);
  };

  const onPhoneNumberChange = (value) => {
    setFormValues('phoneNumber', value);
  };

  const setFormValues = (name, value) => {
    setForm((prevForm) => ({ ...prevForm, [name]: value }));
  };

  const onSubmit = () => {
    const input = {
      firstName: stripToEmpty(form.firstName),
      lastName: stripToEmpty(form.lastName),
      email: stripToEmpty(form.email),
      phoneNumber: stripToEmpty(form.phoneNumber),
      listIds: [contactList.id],
      companyId: currentCompany.id
    };

    props.sendGridSaveContact({ input })
      .then(() => {
        onClose();
      })
      .catch((e) => {
        const formattedError = getError(e);
        setError(formattedError);
        if (v.isString(formattedError)) toast.error(formattedError);
      });
  };

  const initForm = () => {
    const { contact } = params;

    if (contact) {
      const {
        id,
        firstName,
        lastName,
        email,
        phoneNumber
      } = contact;
      setForm({
        id,
        firstName,
        lastName,
        email,
        phoneNumber
      });
    } else setForm({});
    setError({});
  };

  useEffect(() => {
    if (opened) initForm();
  }, [opened]);

  const title = !form.id ? i18n.t('newTitle', i18nOpts) : i18n.t('editTitle', i18nOpts);
  const hasValues = hasFormValues(form);

  return (
    <Modal isOpen={opened}>
      <ModalHeader>{title}</ModalHeader>

      <ModalBody>
        <FormGroup>
          <Label for="firstName">{i18n.t('firstName', i18nOpts)}</Label>
          <Input
            type="text"
            name="firstName"
            id="firstName"
            value={form.firstName || ''}
            onChange={onTextChange}
            invalid={isInputInvalid(error, 'firstName')}
          />
          <InputError name="firstName" error={error} />
        </FormGroup>

        <FormGroup>
          <Label for="lastName">{i18n.t('firstName', i18nOpts)}</Label>
          <Input
            type="text"
            name="lastName"
            id="lastName"
            value={form.lastName || ''}
            onChange={onTextChange}
            invalid={isInputInvalid(error, 'lastName')}
          />
          <InputError name="lastName" error={error} />
        </FormGroup>

        <FormGroup>
          <Label for="email">{i18n.t('email', i18nOpts)}</Label>
          <Input
            type="email"
            name="email"
            id="email"
            value={form.email || ''}
            onChange={onTextChange}
            invalid={isInputInvalid(error, 'email')}
          />
          <InputError name="email" error={error} />
        </FormGroup>

        <FormGroup>
          <Label for="phoneNumber">{i18n.t('phoneNumber', i18nOpts)}</Label>

          <PhoneNumberInput
            value={form.phoneNumber || ''}
            onChange={onPhoneNumberChange}
          />
          <InputError name="phoneNumber" error={error} />
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        <Button color="primary" className="mr-3" onClick={onSubmit} disabled={saving || !hasValues}>
          {saving && (<Spinner size="sm" className="mr-2" />)}
          {i18n.t('buttons.save')}
        </Button>
        <Button outline color="secondary" onClick={onClose} disabled={saving}>
          {i18n.t('buttons.cancel')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default connect((store) => ({
  opened: store.modals[ModalNames.SENDGRID_CONTACT_FORM]?.opened || false,
  params: store.modals[ModalNames.SENDGRID_CONTACT_FORM]?.params || {},
  contactList: store.sendGrid.contactList,
  saving: store.sendGrid.saveContact.loading || false,
  currentCompany: store.companies.currentCompany
}), {
  sendGridSaveContact
})(FormModal);
