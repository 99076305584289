import React, { useEffect, useState } from 'react';
import i18n from 'i18n-js';
import { Helmet } from 'react-helmet';
import { Row, Col } from 'reactstrap';
import { connect } from 'react-redux';

import TrafficByCommunity from './TrafficByCommunityProject';
import TrafficByModel from './TrafficByModelBuildingModel';
import LeadGeneration from './LeadGeneration';
import TrafficByDevice from './TrafficByDevice';
import MostPopularSelections from './MostPopularSelections';
import LeadLocation from './LeadLocation';
import {
  getLastSevenDaysRangeOutput, setDaysRangeInput, getLastSevenDaysRange
} from '../../../utils/dateUtils';
import {
  setReportCommunity, setReportModel, cleanReportItems, setReportBuildingModel, setReportProject
} from '../../../store/actions/overviewActions';
import ReportItemTitle from './ReportItemTitle';
import TopElevations from './TopElevations';
import DateFilter from '../../common/DateFilter';

const i18nOpts = { scope: 'components.admin.overview.index' };

const Overview = ({
  currentCommunity,
  currentModel,
  currentProject,
  currentBuildingModel,
  currentCompany,
  ...props
}) => {
  const [customDateRange, setCustomDateRange] = useState(getLastSevenDaysRangeOutput());
  const [dateRange, setDateRange] = useState(getLastSevenDaysRange());
  const [filter, setFilter] = useState(null);

  useEffect(() => {
    buildFilters();
  }, [
    customDateRange, dateRange, currentBuildingModel,
    currentProject, currentCommunity, currentModel
  ]);

  const onDatePicked = (event, picker) => {
    const pickedDate = [
      picker.startDate.startOf('day').toDate(),
      picker.endDate.endOf('day').toDate()
    ];

    setDateRange(pickedDate);
    setCustomDateRange(setDaysRangeInput(pickedDate));
  };

  const onModelOrBuildingModelPress = (element, models) => {
    props.cleanReportItems().then(() => {
      const modelOrBuildingModel = getItemFromChart(element, models);
      if (modelOrBuildingModel?.type === 'model') props.setReportModel(modelOrBuildingModel);
      else props.setReportBuildingModel(modelOrBuildingModel);
    });
  };

  const onCommunityOrProjectPress = (element, array) => {
    props.cleanReportItems().then(() => {
      const communityOrProject = getItemFromChart(element, array);
      if (communityOrProject?.type === 'community') props.setReportCommunity(communityOrProject);
      else props.setReportProject(communityOrProject);
    });
  };

  const getItemFromChart = (element, array) => {
    if (!element.length) return null;
    const { index: i } = element[0];
    return array[i];
  };

  const buildFilters = () => {
    const filters = {
      companyId: currentCompany.id,
      customDateRange,
      communityId: currentCommunity?.id,
      modelId: currentModel?.id,
      projectId: currentProject?.id,
      buildingModelId: currentBuildingModel?.id
    };
    setFilter(filters);
  };

  return (
    <div>
      <Helmet title={i18n.t('title', i18nOpts)} />
      <div className="d-flex align-items-center mb-4">
        <div className="flex-grow-1">
          <h2>{i18n.t('title', i18nOpts)}</h2>
        </div>
      </div>

      <Row className="">
        <Col lg="6">
          <ReportItemTitle />
        </Col>
        <Col lg="6" md="12" sm="12" xs="12">
          <DateFilter dateRange={dateRange} onDatePicked={onDatePicked} containerClassName="justify-content-end my-4" />
        </Col>
      </Row>
      <Row>
        { (!currentCommunity && !currentProject) && (
          <Col lg="6" md="6" sm="12" xs="12">
            <TrafficByCommunity
              filter={filter}
              onCommunityPress={onCommunityOrProjectPress}
            />
          </Col>
        )}
        { (!currentModel && !currentBuildingModel) && (
          <Col lg="6" md="6" sm="12" xs="12">
            <TrafficByModel
              filter={filter}
              onModelPress={onModelOrBuildingModelPress}
            />
          </Col>
        )}
        <Col lg="6" md="6" sm="12" xs="12">
          <LeadGeneration
            filter={filter}
          />
        </Col>
        <Col lg="6" md="6" sm="12" xs="12">
          <TrafficByDevice
            filter={filter}
          />
        </Col>
        <Col lg="6" md="6" sm="12" xs="12" className="h-auto">
          <MostPopularSelections
            filter={filter}
          />
        </Col>
        { !currentModel && (
          <Col lg="6" md="6" sm="12" xs="12">
            <LeadLocation
              filter={filter}
            />
          </Col>
        )}
        { (currentModel || currentCommunity) && (
          <Col lg="6" md="6" sm="12" xs="12">
            <TopElevations
              filter={filter}
            />
          </Col>
        )}
      </Row>
    </div>
  );
};

export default connect((store) => ({
  currentCommunity: store.overview.community,
  currentModel: store.overview.model,
  currentBuildingModel: store.overview.buildingModel,
  currentProject: store.overview.project,
  currentCompany: store.companies.currentCompany
}), {
  setReportModel,
  setReportCommunity,
  cleanReportItems,
  setReportProject,
  setReportBuildingModel
})(Overview);
