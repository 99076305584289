import React, { useEffect, useState } from 'react';
import { Card, Spinner } from 'reactstrap';
import i18n from 'i18n-js';
import { Bar } from 'react-chartjs-2';
import { connect } from 'react-redux';

import api from '../../../../api';
import './styles.scss';
import { getTopFloorSelectionsQuery, listFloorOptionsQuery } from '../../../../graphql';
import { shortLongName } from '../../../../utils/stringUtils';

const i18nOpts = { scope: 'components.admin.overview.mostPopularSelections.index' };

function fetchFloorOptionsAsync(filter) {
  const variables = { reportFilter: filter };
  return api.graphql(getTopFloorSelectionsQuery, variables)
    .then(({ data: { results } }) => Promise.resolve(results));
}

function getData(floorGroups) {
  return {
    labels: floorGroups.map((f) => shortLongName(f.name)).slice(0, 25),
    datasets: [
      {
        label: i18n.t('timesSelected', i18nOpts).toUpperCase(),
        backgroundColor: '#00AF8C',
        borderWidth: 0,
        data: floorGroups.map((f) => f.selectionsCount).slice(0, 25)
      }
    ]
  };
}

const legend = {
  align: 'start',
  labels: {
    font: {
      color: '#000000',
      size: 10,
      weight: '800'
    },
    boxWidth: 10
  }
};

const getStepSize = (value) => {
  if (value < 30) return 1;

  const dividend = 10 ** Math.trunc(Math.log10(value));
  const remainder = value % dividend;

  return (value - remainder) / 10;
};

const getOptions = (floorOptions) => {
  const highValue = floorOptions.length ? floorOptions[0]?.selectionsCount : 1;
  const stepSize = getStepSize(highValue);

  return {
    scales: {
      x: {
        ticks: {
          major: {
            enabled: true,
          },
          font: {
            color: '#000000',
            weight: '800',
            size: 10
          },
          stepSize,
          beginAtZero: true,
          autoSkip: false
        }
      },
      y: {
        ticks: {
          major: {
            enabled: true,
          },
          font: {
            color: '#000000',
            weight: '800',
            size: 10,
            lineHeight: 1
          },
          autoSkip: false
        }
      }
    },
    indexAxis: 'y',
    plugins: {
      legend
    }
  };
};

function shuffle(sourceArray) {
  for (let i = 0; i < sourceArray.length - 1; i++) {
    const j = i + Math.floor(Math.random() * (sourceArray.length - i));

    const temp = sourceArray[j];
    sourceArray[j] = sourceArray[i];
    sourceArray[i] = temp;
  }
  return sourceArray;
}

function fakeValue() {
  return Math.round(Math.random() * 10);
}

function fetchFloorOptionsSampleAsync(company) {
  const companyId = company.id;
  const variables = { filter: { companyId } };

  return api.graphql(listFloorOptionsQuery, variables)
    .then(({ data: { items } }) => {
      let selectionsCount = 0;
      const floorGroups = shuffle(items).slice(0, 5).map((i) => {
        selectionsCount += fakeValue();
        return { ...i, selectionsCount, name: `${i.floorGroup.name}: ${i.name}` };
      }).reverse();
      return Promise.resolve(floorGroups);
    });
}

function fetchTopFloorTrafficAsync(company, filter) {
  return company.enableSampleData ? fetchFloorOptionsSampleAsync(company)
    : fetchFloorOptionsAsync(filter);
}

const MostPopularSelections = (
  {
    currentCompany, currentCommunity, currentModel, filter
  }
) => {
  const [loading, setLoading] = useState(false);
  const [floorGroups, setFloorGroups] = useState([]);

  useEffect(() => {
    if (!filter) return;

    setLoading(true);
    fetchTopFloorTrafficAsync(
      currentCompany, filter
    )
      .then((items) => setFloorGroups(items))
      .catch(() => {})
      .finally(() => setLoading(false));
  }, [JSON.stringify(filter)]);

  const data = getData(floorGroups);
  const options = getOptions(floorGroups);

  return (
    <Card body className={`m-0 ${(!currentModel && !currentCommunity) ? 'h-100' : ''}`}>
      <h4>{i18n.t('title', i18nOpts)}</h4>

      {loading ? (
        <div className="text-center my-5"><Spinner size="lg" /></div>
      ) : (
        <div className={`d-block my-auto w-auto ${(currentModel || currentCommunity) ? 'my-lg-3' : ''}`}>
          <Bar data={data} options={{ ...options }} />
        </div>
      )}
    </Card>
  );
};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany,
  currentCommunity: store.overview.community,
  currentModel: store.overview.model
}), {})(MostPopularSelections);
