import React, { useState } from 'react';
import {
  Row,
  Col,
  TabContent,
  TabPane
} from 'reactstrap';

import NavTabs from './NavTabs';
import { Tabs } from './utils';
import Installation from './Installation';
import BuildHome from './BuildHome';
import MyHomes from './MyHomes';
import QuickPossessions from './QuickPossessions';
import JavaScriptSdk from './JavaScriptSdk';
import GetVerified from './GetVerified';
import ReserveHome from './ReserveHome';
import GetRentalVerified from './GetRentalVerified';

const BuildHomeSetup = () => {
  const [activeTab, setActiveTab] = useState(Tabs.INSTALLATION);

  const onTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <Row>
      <Col xs={12} sm={4} md={3}>
        <NavTabs activeTab={activeTab} onChange={onTabChange} />
      </Col>

      <Col xs={12} sm={8} md={9}>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={Tabs.INSTALLATION}>
            <Installation />
          </TabPane>
          <TabPane tabId={Tabs.BUILD_HOME}>
            <BuildHome />
          </TabPane>
          <TabPane tabId={Tabs.RESERVE_HOME}>
            <ReserveHome />
          </TabPane>
          <TabPane tabId={Tabs.MY_HOMES}>
            <MyHomes />
          </TabPane>
          <TabPane tabId={Tabs.QUICK_POSSESSIONS}>
            <QuickPossessions />
          </TabPane>
          <TabPane tabId={Tabs.JAVASCRIPT_SDK}>
            <JavaScriptSdk />
          </TabPane>
          <TabPane tabId={Tabs.GET_VERIFIED}>
            <GetVerified />
          </TabPane>
          <TabPane tabId={Tabs.GET_RENTAL_VERIFIED}>
            <GetRentalVerified />
          </TabPane>
        </TabContent>
      </Col>
    </Row>
  );
};

BuildHomeSetup.propTypes = {};

BuildHomeSetup.defaultProps = {};

export default BuildHomeSetup;
