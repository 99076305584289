import React, { Component } from 'react';
import * as PropTypes from 'prop-types';
import {
  ListGroupItem, Row, Col, Badge
} from 'reactstrap';
import { connect } from 'react-redux';
import i18n from 'i18n-js';
import v from 'voca';
import { BiGridVertical, BiGitBranch } from 'react-icons/bi';
import { IoMdImages, IoMdPricetags } from 'react-icons/io';
import { MdVisibility } from 'react-icons/md';

import './styles.scss';
import { DEFAULT_IMAGE, FloorableTypes, ModalNames } from '../../../../../constants';
import ModalService from '../../../../../services/ModalService';
import { deleteFloorOption } from '../../../../../store/actions/floorOptionActions';
import ActionsMenu from './ActionsMenu';
import { formatNumber } from '../../../../../utils/currencyUtils';
import { isAdmin } from '../../../../../utils/authUtils';
import { setReloadFloors } from '../../../../../store/actions/floorActions';

const i18nOpts = { scope: 'components.global.floorsManager.floor.floorOption.index' };

class FloorOption extends Component {
  constructor(props) {
    super(props);

    this.state = {
      focused: false
    };

    this.onEdit = this.onEdit.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.onToggleFocused = this.onToggleFocused.bind(this);
    this.onDependencyEdit = this.onDependencyEdit.bind(this);
    this.onImagesPress = this.onImagesPress.bind(this);
    this.onPriceRulesClick = this.onPriceRulesClick.bind(this);
  }

  onEdit() {
    const { floorGroup, floorOption, floorableType } = this.props;
    ModalService.open(ModalNames.FLOOR_OPTION_FORM, { floorGroup, floorOption, floorableType });
  }

  onDelete() {
    const { props } = this;
    const { floorOption } = this.props;

    props.deleteFloorOption(floorOption.id)
      .then(() => props.setReloadFloors(true));
  }

  onDependencyEdit() {
    const { floorGroup, floorOption } = this.props;
    ModalService.open(ModalNames.FLOOR_OPTION_DEPENDENCY, { floorGroup, floorOption });
  }

  onImagesPress() {
    const { floorOption } = this.props;
    ModalService.open(ModalNames.FLOOR_OPTION_IMAGES, { floorOption });
  }

  onPriceRulesClick() {
    const { floorOption } = this.props;
    ModalService.open(ModalNames.FLOOR_OPTION_PRICE_RULES, { floorOption });
  }

  onToggleFocused(focused) {
    this.setState({ focused });
  }

  render() {
    const { floorOption, floorableType } = this.props;
    const { focused } = this.state;

    if (!floorOption) return null;

    const price = parseFloat(floorOption.priceChange) || 0;
    const size = parseFloat(floorOption.sizeChange) || 0;
    const beds = parseFloat(floorOption.bedsChange) || 0;
    const baths = parseFloat(floorOption.bathsChange) || 0;
    const hasChanges = [price, size, beds, baths].some((c) => c !== 0);
    const hasAlternatives = [floorOption.alternateImageUrl, floorOption.alternateOption]
      .some((o) => !v.isBlank(o));
    const hasDependencies = floorOption.dependencies.length > 0;
    const hasAvailabilityCommunities = !v.isBlank(floorOption.availabilityRule)
      && floorOption.availabilityCommunities?.length > 0;

    return (
      <ListGroupItem
        onMouseEnter={() => this.onToggleFocused(true)}
        onMouseLeave={() => this.onToggleFocused(false)}
        className="floor-option-container sortable-floor-option-item"
      >
        <div className="d-flex">
          <div className="pr-3">
            <img src={floorOption.imageUrl || DEFAULT_IMAGE} alt="" className="floor-option-image" />
          </div>
          <div className="flex-grow-1">
            <div className="d-flex mb-2">
              <div className="flex-grow-1 d-flex pb-2">
                <h6 className="mb-0">{floorOption.name}</h6>
                {floorOption.isDefault && (
                  <div className="ml-3">
                    <Badge color="info" pill className="align-top">{i18n.t('isDefault', i18nOpts)}</Badge>
                  </div>
                )}
              </div>
              <div>
                <div className="d-flex">
                  {isAdmin() && focused && (
                    <>
                      <BiGridVertical size="1.2rem" className="sortable-floor-option-handler mr-3" title={i18n.t('buttons.sort')} />
                      <ActionsMenu
                        floorableType={floorableType}
                        onEdit={this.onEdit}
                        onDelete={this.onDelete}
                        onDependencyEdit={this.onDependencyEdit}
                        onImagesPress={this.onImagesPress}
                        onPriceRulesClick={this.onPriceRulesClick}
                      />
                    </>
                  )}
                </div>

              </div>
            </div>

            {!v.isBlank(floorOption.description) && (
              <div className="mb-2">
                <small className="text-muted font-italic white-space-pre-line">{floorOption.description}</small>
              </div>
            )}

            {hasChanges && (
              <div className="mb-2">
                <div>
                  <small>{i18n.t('changes', i18nOpts)}</small>
                </div>

                <Row>
                  {price !== 0 && (
                    <Col lg="3" md="4" sm="12" xs="6">
                      <small className="text-muted mr-2">{formatNumber(price)}</small>
                      <i className="fas fa-dollar-sign text-muted" />
                    </Col>
                  )}
                  {size !== 0 && (
                    <Col lg="3" md="4" sm="12" xs="6">
                      <small className="text-muted mr-2">{formatNumber(size)}</small>
                      <i className="fas fa-expand text-muted" />
                    </Col>
                  )}
                  {beds !== 0 && (
                    <Col lg="3" md="4" sm="12" xs="6">
                      <small className="text-muted mr-2">{beds}</small>
                      <i className="fas fa-bed text-muted" />
                    </Col>
                  )}
                  {baths !== 0 && (
                    <Col lg="3" md="4" sm="12" xs="6">
                      <strong className="text-muted mr-2">{baths}</strong>
                      <i className="fas fa-bath text-muted" />
                    </Col>
                  )}
                </Row>
              </div>
            )}

            <Row>
              {hasAlternatives && (
                <Col xs="12" sm="12" md="6" xl="4">
                  <div>
                    <small>{i18n.t('alternatives', i18nOpts)}</small>
                  </div>

                  <div className="mb-2">
                    {!v.isBlank(floorOption.alternateImageUrl) && (
                      <img src={floorOption.alternateImageUrl} alt="" className="floor-option-alternate-image mr-3" />
                    )}
                    {!v.isBlank(floorOption.alternateOption) && (
                      <span>{floorOption.alternateOption}</span>
                    )}
                  </div>
                </Col>
              )}
              {hasDependencies && (
                <Col xs="12" sm="12" md="6" xl="4">
                  <div>
                    <small>{i18n.t('dependencies', i18nOpts)}</small>
                  </div>

                  <div className="mb-2">
                    <BiGitBranch size="1rem" className="mr-2" />
                    <span className="text-muted">{i18n.t('yes', i18nOpts)}</span>
                  </div>
                </Col>
              )}
              {floorOption.imagesCount > 0 && (
                <Col xs="12" sm="12" md="6" xl="4">
                  <div>
                    <small>{i18n.t('images', i18nOpts)}</small>
                  </div>

                  <div className="mb-2">
                    <IoMdImages size="1rem" className="mr-2" />
                    <span className="text-muted">{floorOption.imagesCount}</span>
                  </div>
                </Col>
              )}
              {floorOption.priceRulesCount > 0 && (
                <Col xs="12" sm="12" md="6" xl="4">
                  <div>
                    <small>{i18n.t('priceRules', i18nOpts)}</small>
                  </div>

                  <div className="mb-2">
                    <IoMdPricetags size="1rem" className="mr-2" />
                    <span className="text-muted">{floorOption.priceRulesCount}</span>
                  </div>
                </Col>
              )}
              {hasAvailabilityCommunities && (
                <Col xs="12" sm="12" md="6" xl="4">
                  <div>
                    <small>{i18n.t('availabilityRule', i18nOpts)}</small>
                  </div>

                  <div className="mb-2">
                    <MdVisibility size="1rem" className="mr-2" />
                    <span className="text-muted">{i18n.t('yes', i18nOpts)}</span>
                  </div>
                </Col>
              )}
            </Row>

          </div>
        </div>
      </ListGroupItem>
    );
  }
}

FloorOption.propTypes = {
  floorGroup: PropTypes.objectOf(PropTypes.any).isRequired,
  floorOption: PropTypes.objectOf(PropTypes.any).isRequired,
  floorableType: PropTypes.oneOf(Object.values(FloorableTypes)).isRequired,
};

FloorOption.defaultProps = {};

export default connect((store) => ({
}), {
  deleteFloorOption,
  setReloadFloors
})(FloorOption);
