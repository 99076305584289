import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-js';
import moment from 'moment';

import { DateFormats } from '../../../../../../../../../constants';
import HomeCard from '../../../../../../../../global/HomeCard';
import Price from '../../../../../../../../global/home/Price';
import { InterestTypes } from '../../utils';
import { getHomeInfo } from '../../../../../../../../../utils/homeUtils';

const i18nOpts = { scope: 'components.admin.customers.show.details.deals.interests.interestList.listItem.index' };

const ListItem = ({ interest }) => {
  let type;

  const getInterestTypeName = () => {
    if (type === InterestTypes.UNIT) return i18n.t('types.unit', i18nOpts);
    if (type === InterestTypes.QUICK_POSSESSION) return i18n.t('types.quickPossession', i18nOpts);
    if (type === InterestTypes.LOT) return i18n.t('types.lot', i18nOpts);
    return i18n.t('types.model', i18nOpts);
  };

  const { interactionType, ...home } = interest;
  const homeInfo = getHomeInfo(home);
  const { communityProjectName } = homeInfo;
  const listedPrice = home.totalPrice;
  const { lastActive } = home;

  const { isQuickPossession } = homeInfo;
  if (home.multiFamilyHome) type = InterestTypes.UNIT;
  else if (isQuickPossession) type = InterestTypes.QUICK_POSSESSION;
  else if (home.singleFamilyHome.isLot) type = InterestTypes.LOT;
  else type = InterestTypes.MODEL;

  return (
    <tr>
      <td>
        <span className="font-weight-500 col-gray-900">{getInterestTypeName()}</span>
      </td>
      <td>
        <HomeCard home={interest} hidePrice hideBuiltDate hideTotalOffers />
      </td>
      <td>
        <Price amount={listedPrice} className="font-weight-500 col-gray-900" />
      </td>
      <td>
        <span className="font-weight-500 col-gray-900">{communityProjectName}</span>
      </td>
      <td>
        {moment(lastActive).format(DateFormats.MMM_DD_YYYY)}
      </td>
    </tr>
  );
};

ListItem.propTypes = {
  interest: PropTypes.objectOf(PropTypes.any).isRequired
};

ListItem.defaultProps = {};

export default ListItem;
