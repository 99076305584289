import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import i18n from 'i18n-js';
import { Card, Row, Col } from 'reactstrap';

import CommunitiesProjects from './CommunitiesProjects';
import InterestList from './InterestList';
import {
  filterInterests,
  getCommunitiesProjectsFromInterests,
  getSavedHomesFromHomes,
  sortInterests
} from './utils';
import { setCustomerInterestFilters } from '../../../../../../../store/actions/customerActions';
import { listHomes } from '../../../../../../../store/actions/homeActions';

const i18nOpts = { scope: 'components.admin.customers.show.details.deals.interests.index' };

const Interests = ({
  homes, customer, currentCompany, interestFilters, ...props
}) => {
  const [interests, setInterests] = useState([]);

  const loadHomes = () => {
    const variables = {
      filter: { contactId: customer.id, companyId: currentCompany.id },
      withTotalOffers: true,
      companyId: currentCompany.id,
    };
    props.listHomes(variables);
  };

  const loadInterests = () => {
    const savedHomes = getSavedHomesFromHomes(homes);
    const mergedAndSortedInterests = sortInterests(savedHomes);
    setInterests(mergedAndSortedInterests);
  };

  useEffect(() => {
    loadHomes();
    props.setCustomerInterestFilters({ communitiesProjects: [] });
  }, []);

  useEffect(() => {
    loadInterests();
  }, [homes]);

  const communitiesProjects = useMemo(() => getCommunitiesProjectsFromInterests(interests),
    [interests]);

  const filteredInterests = useMemo(() => {
    const selectedCommunitiesProjects = interestFilters.communitiesProjects || [];
    return filterInterests(interests, selectedCommunitiesProjects);
  }, [interests, interestFilters]);

  if (!filteredInterests.length) return null;

  return (
    <Card body className="p-0">
      <div className="p-4">
        <Row>
          <Col xs="12" sm="4" md="12" lg="5">
            <h4 className="mb-0">{i18n.t('title', i18nOpts)}</h4>
          </Col>
          <Col xs="12" sm="8" md="12" lg="7" className="pt-2 pt-sm-0 pt-md-2 pt-lg-0">
            <CommunitiesProjects communitiesProjects={communitiesProjects} />
          </Col>
        </Row>
      </div>

      <InterestList interests={filteredInterests} />
    </Card>
  );
};

Interests.propTypes = {};

Interests.defaultProps = {};

export default connect((store) => ({
  currentCompany: store.companies.currentCompany,
  customer: store.customers.customer,
  homes: store.homes.homes,
  interestFilters: store.customers.interestFilters
}), {
  listHomes,
  setCustomerInterestFilters
})(Interests);
